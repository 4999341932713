.App {
  text-align: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 120vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.content {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #ffffff;
}

.chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 100%; /* Augmenter encore la hauteur maximale */
  overflow-y: auto;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.message {
  margin-bottom: 15px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.message.user {
  margin-left: auto;
  background-color: #d1e7ff;
  color: #000;
  align-self: flex-end;
  text-align: right;
}

.message.agent,
.message.assistant {
  background-color: #f1f1f1;
  color: #333;
  align-self: flex-start;
  text-align: left;
}

.message-content {
  padding: 12px 16px;
  border-radius: 15px;
  font-size: 14px;
  line-height: 1.4;
}

.message.user .message-content {
  background-color: #007bff;
  color: white;
  border-radius: 15px 15px 0 15px;
}

.message.agent .message-content,
.message.assistant .message-content {
  background-color: #e9ecef;
  color: #2c3e50;
  border-radius: 15px 15px 15px 0;
}

.message-timestamp {
  font-size: 12px;
  color: #6c757d;
  margin-top: 4px;
}

.typing-indicator {
  padding: 12px 16px;
  background-color: #e9ecef;
  color: #2c3e50;
  border-radius: 15px;
  font-size: 14px;
  max-width: fit-content;
  margin-bottom: 15px;
  animation: pulse 1.5s infinite;
}

.status-bar {
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 10px;
  text-align: center;
  color: #6c757d;
  font-size: 14px;
}

.conversation-timer {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  z-index: 1000;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* Scrollbar styling */
.messages::-webkit-scrollbar {
  width: 8px;
}

.messages::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.garage-info {
  margin: 20px 0;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.garage-info h2 {
  margin-bottom: 10px;
  color: #333;
}

.garage-info p {
  margin-bottom: 10px;
  color: #555;
}

.garage-info ul {
  list-style-type: none;
  padding: 0;
}

.garage-info li {
  margin-bottom: 5px;
  color: #777;
}

.assistant-features {
  margin: 20px 0;
  padding: 15px;
  background-color: #e9f7ef;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.assistant-features h3 {
  margin-bottom: 10px;
  color: #2c3e50;
}

.assistant-features ul {
  list-style-type: disc;
  padding-left: 20px;
}

.assistant-features li {
  margin-bottom: 5px;
  color: #34495e;
}

.assistant-features button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  margin: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.assistant-features button:hover {
  background-color: #45a049;
}

.start-btn {
  padding: 12px 24px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.start-btn:hover {
  opacity: 0.9;
  transform: scale(1.05);
}

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.home-page h1 {
  color: #333;
  margin-bottom: 40px;
}

.navigation-buttons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.nav-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #0056b3;
}

.client-chat-page,
.garagist-chat-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.client-chat-page h1,
.garagist-chat-page h1 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .chat-container {
    max-height: 50vh;
  }
}
